<template>
  <div class="section-toggle-switch">
    <label class="switch">
      <input type="checkbox" v-model="isChecked" @change="switchChange">
      <div class="slider round" />
    </label>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isChecked: true,
    };
  },
  methods: {
    switchChange() {
      this.$emit('switchChange', this.isChecked);
    },
  },
};
</script>

<style lang="sass" scoped>
.section-toggle-switch
  @apply h-8

.switch
  @apply relative inline-block w-12 h-8

input
  display: none

.slider
  @apply absolute cursor-pointer rounded-radius-16 top-0 bottom-0 left-0 right-0 bg-gray-light
  transition: background-color var(--transition)

  &:before
    @apply absolute bg-white w-7 h-7
    content: ''
    left: 2px
    bottom: 2px
    transition: transform var(--transition)

input:checked + .slider
  background: var(--primary-back-color, var(--klubba-primary-back-color))
  &:before
    transform: translate3d(16px, 0, 0)

.round:before
  border-radius: 50%
</style>
