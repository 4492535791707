<template>
  <div class="page-start-date page-content">
    <KlubbaRichText class="texts">
      <!-- <p><b>Your subscription start date</b></p> -->
      <p>Your subscription will begin today, <b v-text="dateToText(billingDate)" />.</p>
      <p>Your subscription is billed <b>monthly</b>.</p>
      <p>Your next billing date will be <b v-text="dateToText(nextBillingDate)" />.</p>
    </KlubbaRichText>

    <div class="container">
      <div class="toggle-wrap">
        <klubba-toggle-switch @switchChange="checkSwitch"/>
        <p class="renew">Automatically renew membership </p>
      </div>
    </div>

    <div class="button-wrap">
      <klubba-footer
        backButton="Back"
        :nextDisabled="!billingDate"
        @goPrevPage="prevPage"
        @goNextPage="nextPage"
      />
    </div>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex';
import moment from 'moment';

import KlubbaFooter from '@/components/layout/KlubbaFooter.vue';
import KlubbaToggleSwitch from '@/components/default/KlubbaToggleSwitch.vue';
import KlubbaRichText from '@/components/default/KlubbaRichText.vue';

export default {
  components: {
    KlubbaRichText,
    KlubbaFooter,
    KlubbaToggleSwitch,
  },
  data() {
    return {
      isAutoRenew: true,
      billingDate: null,
      nextBillingDate: 'DD/MM/YYYY',
    };
  },
  mounted() {
    this.billingDate = moment().valueOf();

    if (this.billingInfo?.billing_date) {
      this.billingDate = this.billingInfo?.billing_date;
    }

    if (this.billingInfo?.renew) {
      this.isAutoRenew = this.billingInfo?.renew;
    }

    if (this.preSelectedDate) {
      this.billingDate = this.preSelectedDate;
    }
  },
  watch: {
    billingDate: {
      handler(billingDate) {
        if (billingDate) {
          this.nextBillingDate = moment(billingDate)
            .add(1, 'months').valueOf();
        }
      },
    },
  },
  computed: {
    preSelectedDate() {
      return this.$store.getters.preSelectedDate;
    },
    club() {
      return this.$store.getters.club;
    },
  },
  methods: {
    ...mapMutations(['setBillingInfo']),
    ...mapGetters(['billingInfo']),
    dateToText(date) {
      const momentDate = moment(date);
      return `${momentDate.format('dddd')} the ${momentDate.format('Do')} of ${momentDate.format('MMMM')}`;
    },
    prevPage() {
      this.$router.push(`/${this.club.id}/add-wallet`);
    },
    nextPage() {
      const billingInfo = {
        billing_date: moment(this.billingDate).isSame(moment(), 'day') ? 'now' : this.billingDate,
        renew: this.isAutoRenew,
      };
      this.setBillingInfo(billingInfo);
      this.$router.push(`/${this.club.id}/club-questions`);
    },
    checkSwitch(isChecked) {
      this.isAutoRenew = isChecked;
    },
  },
};
</script>

<style lang="sass" scoped>
.texts
  @apply p-8

.button-wrap
  @apply p-8 mt-auto

.container
  @apply px-8

.card-wrap
  @apply bg-white p-5 rounded-radius-10

.start-today
  @apply underline cursor-pointer leading-5 mb-6 font-medium
  color: var(--primary-back-color, var(--klubba-primary-back-color))

.next-billing
  @apply mt-4 bg-background text-black-light mt-4 mb-7 py-4 px-3 text-center rounded-radius-10
  font-size: 13px

.toggle-wrap
  @apply flex items-center

.renew
  @apply leading-6 font-medium ml-5 flex-1
  font-size: 13px
  letter-spacing: 0.5px
</style>
